import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3100744155/src/autobackup-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "restore"
    }}>{`Restore`}</h1>
    <p>{`Backups of all resources are stored in customer accounts. AutoBackup stores only meta-data which may help to locate a desired resource’s backup (cloud account, region identifier of a backup). AutoBackup also records information about backup creation time. To restore a snapshot from a specified date, browse the backup events list, find information about a backup you’re interested in and read its meta-data. Further steps depend on a type of resource you want to restore. They are described in the sections below.`}</p>
    <h2 {...{
      "id": "ebs-volumes"
    }}>{`EBS volumes`}</h2>
    <p>{`Go to the AWS console and log in to an account where EBS volume snapshot is located and share it with a destination account to which you want to restore it. Next, copy the snapshot to the destination account and region. Use the AWS EBS snapshots management tools to restore the snapshot. The snapshot’s meta-data contain information read from `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/reference/ec2/describe-volumes.html"
      }}>{`describe-volumes`}</a>{` AWS API call. The meta-data can be used to determine where the original volume was located, to which EC2 instance it was attached and what tags where put on the original volume.`}</p>
    <h2 {...{
      "id": "ec2-instances"
    }}>{`EC2 instances`}</h2>
    <p>{`Go to the AWS console and log in to an account where EBS volumes that belong to the EC2 instance being recovered are located and share them with a destination account to which you want to restore them. Next, copy them to the destination account and region, use the AWS EBS snapshots management tools to restore the snapshots. Next, launch an EC2 instance. During the EC2 instance configuration use the backup metadata retrieved from the AutoBackup to select correct AMI, tagging, etc (meta-data contain the output of the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/reference/ec2/describe-instances.html"
      }}>{`describe-instances`}</a>{` AWS API call). Finally, attach restored EBS volumes to the EC2 instance.`}</p>
    <h2 {...{
      "id": "rds-instances-and-clusters"
    }}>{`RDS instances and clusters`}</h2>
    <p>{`Go to the AWS console and log in to an account where an RDS snapshot is located and share it with a destination account to which you want to restore it. Next, copy it to the destination account and region, use the AWS RDS snapshots management tools to restore the snapshot. While selecting RDS snapshot restore options (such as database instance size, multi-az, maintenance window etc.), use the backup meta-data retrieved from the AutoBackup (metadata contain the output of the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/reference/rds/describe-db-instances.html"
      }}>{`describe-db-instances`}</a>{` or `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/reference/rds/describe-db-clusters.html"
      }}>{`describe-db-clusters`}</a>{` AWS API calls depending on a type of database you’re restoring).`}</p>
    <h2 {...{
      "id": "s3"
    }}>{`S3`}</h2>
    <p>{`Create a new S3 bucket to which you want your replicated objects to be restored. Using meta-data gathered from the AutoBackup, locate an S3 bucket that stores replicas of original objects which you want to be restored. Next, copy the content of the bucket in which replicas are being stored to the bucket which you’ve created to store restored objects (use AWS S3 API to perform copying).`}</p>
    <p>{`Keep in mind that S3 backup is implemented using same-region/cross-region replication native AWS S3 mechanisms, which has the following implications:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`replicas storing bucket does not contain objects from original bucket which were put before enabling replication,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the objects you see in the replica bucket eventually reflect the most recent state of the original bucket. It means that it’s not easy to restore a bucket state from a specific point in time. To achieve it, you may take an advantage of the S3 versioning mechanism which is enabled on replica bucket and copy only the required versions of replicated objects.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "dynamodb"
    }}>{`DynamoDB`}</h2>
    <p>{`For DynamoDB, the backup meta-data contain information about original table indexes, tags, read/write capacity units and stream settings. For replica snapshots, also a name of an S3 bucket containing replicated table data is stored in backup meta-data. The restore process is different for primary and replica snapshots.`}</p>
    <p>{`Primary snapshot can be restored only in a region and an account in which the snapshot is located. Use native DynamoDB tools to create a table from the snapshot. Use the meta-data gathered from AutoBackup to restore table indexes and set read/write capacity units.`}</p>
    <p>{`Replica snapshots are stored as objects in an S3 bucket. You can locate the bucket and a common prefix of objects representing a snapshot using the meta-data from AutoBackup. Each object contains a list of DynamoDB JSON objects. The syntax of each object is the same as the one used by the DynamoDB `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Programming.LowLevelAPI.html"
      }}>{`API`}</a>{`. To restore a DynamoDB table from replica snapshot, you need to iterate over the S3 objects containing DynamoDB table data and put data from the object to a new DynamoDB table (you can use `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_BatchWriteItem.html"
      }}>{`BatchWriteItem`}</a>{`).`}</p>
    <h2 {...{
      "id": "redshift"
    }}>{`Redshift`}</h2>
    <p>{`For Redshift AutoBackup takes snapshots only in the region and account where an original resource is located. Snapshot meta-data contain information fetched using `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/reference/redshift/describe-clusters.html"
      }}>{`describe-clusters`}</a>{`.  Use the meta-data to locate a snapshot, then restore a desired Redshift cluster using AWS Redshift snapshots restoring tools.`}</p>
    <h2 {...{
      "id": "efs"
    }}>{`EFS`}</h2>
    <p>{`EFS filesystems primary backups are created using AWS Backup Vault service. On the other hand, data stored on EFS filesystems is replicated to S3 bucket.`}</p>
    <p>{`In order to restore primary EFS snapshot, use backup meta-data gathered from AutoBackup to locate a desired snapshot in AWS Backup Vault service, then use the AWS Backup Vault tools to `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/aws-backup/latest/devguide/restoring-a-backup.html"
      }}>{`perform restoring`}</a>{`.`}</p>
    <p>{`EFS replicas are stored in an S3 bucket pointed in a snapshot’s meta-data. To restore files replicated to S3, you must first create and mount a new EFS file system (or re-use existing) to which data will be restored. Then you need to use AWS S3 API commands to copy desired objects to the mounted EFS filesystem.`}</p>
    <p>{`Name of a bucket which stores EFS filesystems replicas can be found in snapshot meta-data. In the bucket, snapshots are located under prefixes constructed using the following format `}<inlineCode parentName="p">{`aws-efs/{filesystem-id}/{backup-event-date}`}</inlineCode>{`, where `}<inlineCode parentName="p">{`{filesystem-id}`}</inlineCode>{` denotes an identifier of filesystem, and `}<inlineCode parentName="p">{`{backup-event-date}`}</inlineCode>{` denotes a date of snapshot creation. Copy all the objects stored under one of prefixes with described format.`}</p>
    <h2 {...{
      "id": "route-53"
    }}>{`Route 53`}</h2>
    <p>{`Route53 snapshots are stored in S3 buckets. First, health checks should be restored (`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/Route53/latest/APIReference/API_CreateHealthCheck.html"
      }}>{`CreateHealthCheck`}</a>{`), next hosted zones (`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/Route53/latest/APIReference/API_CreateHostedZone.html"
      }}>{`CreateHostedZone`}</a>{`), the last one to restore are traffic policies (`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/Route53/latest/APIReference/API_CreateTrafficPolicy.html"
      }}>{`CreateTrafficPolicy`}</a>{`).`}</p>
    <p>{`Notice that optional CloudWatch alarms that monitor Health Checks statuses are not backed up. It should be obvious as these are part of the CloudWatch service, not Route 53. This just has to be clarified because the alarms are also shown in the Route 53 section of AWS Management Console which may be misleading.`}</p>
    <h2 {...{
      "id": "azure-vm"
    }}>{`Azure VM`}</h2>
    <p>{`Azure VMs are backed up using native `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/backup/backup-azure-recovery-services-vault-overview"
      }}>{`Azure Backup vaults`}</a>{`. In order to restore an Azure VM, use the restore mechanism provided by Azure. The restore process with different scenarios is described in official Azure documentation, available `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/backup/backup-azure-arm-restore-vms"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "azure-sap-hana"
    }}>{`Azure SAP HANA`}</h2>
    <p>{`Azure SAP HANA is backed up using native `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/backup/backup-azure-recovery-services-vault-overview"
      }}>{`Azure Backup vaults`}</a>{`. In order to restore an Azure SAP HANA, use the restore mechanism provided by Azure. The restore process with different scenarios is described in official Azure documentation, available `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/backup/sap-hana-db-restore"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "gcp-snapshot"
    }}>{`GCP Snapshot`}</h2>
    <p>{`In GCP snapshots can not be copied between projects. To work around this a temporary `}<inlineCode parentName="p">{`image`}</inlineCode>{` need to be created. Follow the following steps to restore the latest snapshot.`}</p>
    <h4 {...{
      "id": "setup-the-shell--requires-bash-4-"
    }}>{`Setup the shell ( requires bash 4+ )`}</h4>
    <p>{`Replace values matching your environment`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`declare -Ax labels
declare -x vault_project=<VAULT PROJECT ID>
declare -x target_project=<TARGET PROJECT ID>
declare -x owner_resource=<VM NAME>
declare -x owner_zone=<VM ZONE NAME>
declare -x restore_region=<VM REGION>
declare -x snapshot disk_size
declare -x tmp_disk="tmp-$(uuidgen)"
declare -x tmp_image="tmp-$(uuidgen)"


labels[nc-backup-snapshot-owner-account]=$target_project
labels[nc-backup-snapshot-owner-resource]=$owner_resource
labels[nc-backup-snapshot-owner-zone]=$owner_zone

label_query="$(for k in \${!labels[@]}; do echo "labels.$k=\${labels[$k]} AND "; done;)"
label_query=\${label_query:0:-4}

`}</code></pre>
    <h4 {...{
      "id": "grab-latest-snapshot-and-size"
    }}>{`Grab latest snapshot and size`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`declare -a _tmp_arr
_tmp_arr=($(gcloud compute snapshots list --project $vault_project --filter="$label_query" --sort-by=creationTimestamp | tail -n1 | tr -s ' ' |  cut -f1,2 -d' '))
snapshot=\${_tmp_arr[0]}
disk_size=\${_tmp_arr[1]}
`}</code></pre>
    <h4 {...{
      "id": "create-tmp-image"
    }}>{`Create tmp image`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gcloud compute images create $tmp_image --project=$vault_project --family=restore --source-snapshot=$snapshot --storage-location=$restore_region
`}</code></pre>
    <h4 {...{
      "id": "create-disk-from-tmp-image"
    }}>{`Create disk from tmp image`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gcloud beta compute disks create $tmp_disk --image-project=$vault_project --type=pd-standard --size="\${disk_size}GB" --zone=$owner_zone --image=$tmp_image --project $target_project
`}</code></pre>
    <h4 {...{
      "id": "create-the-snapshot"
    }}>{`Create the snapshot`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gcloud compute disks snapshot $tmp_disk --snapshot-names=$snapshot --zone=$owner_zone --storage-location=$restore_region --project $target_project
`}</code></pre>
    <h4 {...{
      "id": "delete-tmp-disk"
    }}>{`Delete tmp disk`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gcloud compute disks delete $tmp_disk --project=$target_project --zone $owner_zone
`}</code></pre>
    <h4 {...{
      "id": "delete-tmp-image"
    }}>{`Delete tmp image`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gcloud compute images delete $tmp_image --project=$vault_project
`}</code></pre>
    <h2 {...{
      "id": "ibm-cloud-vm"
    }}>{`IBM Cloud VM`}</h2>
    <p>{`For VMs AutoBackup takes snapshots only in the region and account where an original resource is located. Snapshot's metadata contain VM and volumes information, and list of taken snapshots.
Use this metadata to locate volumes' snapshots and follow `}<a parentName="p" {...{
        "href": "https://cloud.ibm.com/docs/vpc?topic=vpc-snapshots-vpc-restore&interface=ui#snapshots-vpc-restore-vol-UI"
      }}>{`this instruction`}</a>{` to restore desired VM.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      